import React from "react";
import cus1 from "../../assets/cus1.png";
import cus2 from "../../assets/cus2.png";
import cus21 from "../../assets/cus21.png";
import Vector from "../../assets/Vector.svg";

const Partners = () => {
  return (
    <section title="partners" className="px-4 md:px-10 py-8 md:py-20">
      <div className="text-center md:text-left">
        <h1 className="text-4xl md:text-5xl font-bold mb-6">
          Our Esteemed Partners
        </h1>
        <img
          src={Vector}
          alt="vector"
          className="w-full md:w-[30rem] mx-auto md:mx-0 mb-6"
        />
        <p className="text-lg md:text-xl max-w-[50rem] mx-auto md:mx-0 mb-10 md:mb-5 px-4 md:px-0">
          At Sprhava, we believe in the power of collaboration. Our partnerships
          with leading companies and organizations help us drive innovation and
          deliver exceptional solutions.
        </p>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center md:items-start mt-20">
        <div className="w-full md:w-1/2 px-4 md:px-0 mb-8 md:mb-0">
          <div className="relative">
            <img src={cus1} alt="cus1" className="w-full md:w-[455px] h-auto" />
            <p className="absolute top-[85%] left-1/2 transform -translate-x-1/2 w-[80%] md:w-[30rem] p-4 md:p-8 rounded-md bg-white bg-opacity-80 backdrop-blur-lg shadow-xl text-black">
              Through their comprehensive approach, they advocate for the rights
              and inclusion of people with disabilities, significantly impacting
              countless lives across India.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4 md:px-0 mt-20 sm:mt-0">
          <h1 className="font-bold text-2xl md:text-3xl mb-4">
            Our social organization partner, Samarthanam Trust for the Disabled,
          </h1>
          <div className="space-y-4 text-lg">
            <p>
              Is a leading non-profit dedicated to empowering individuals with
              disabilities based in India.
            </p>
            <p>
              Founded in 1997, they focus on education, employment, sports, and
              rehabilitation to promote an inclusive society.
            </p>
            <p>
              Notable initiatives include a sports academy for athletes with
              disabilities and various educational and vocational training
              programs.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center md:items-start md:gap-20 mt-8 md:mt-28">
        <div className="w-full md:w-1/2 px-4 md:px-0">
          <h1 className="font-bold text-xl md:text-2xl lg:text-3xl mb-4">
            Bayerischer Blinden- und Sehbehindertenbund e. V. (BBSB)
          </h1>
          <div className="space-y-4 text-base md:text-lg lg:text-xl">
            <p>
              is an organization that has been a cornerstone in advocating for
              the rights and welfare of visually impaired individuals in Bavaria
              since 1916.
            </p>
            <p>
              Their comprehensive efforts empower the blind and visually
              impaired community to lead independent and fulfilling lives.
            </p>
            <p>
              Their efforts are pivotal in advancing accessibility, delivering
              indispensable support services, and fostering educational and
              vocational opportunities.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4 md:px-0 mb-8 md:mb-0">
          <div className="relative">
            <img src={cus2} alt="cus1" className="w-full md:w-[369px] h-auto" />
            <img
              src={cus21}
              className="absolute md:top-[16rem] md:left-[-6rem] top-[336px] left-[50%] transform -translate-x-1/2 shadow-sm rounded-md w-[15rem] md:w-[24rem] p-4 md:p-8"
              alt="cus21"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
