import React from "react";
import ourVision from "../../assets/vision.png";
import ourValues from "../../assets/values.png";
import ourCapabilities from "../../assets/capabilities.png";
const Goal = () => {
  return (
    <section title="goal" className="md:px-28 md:py-24 py-14 w-[80%] mx-auto">
      <div className="">
        <div className="mb-5 md:mb-0 md:flex md:flex-row sm:flex sm:flex-col gap-10 items-center md:shadow-none shadow-lg rounded-xl py-5 px-5 md:p-0">
          <div className="mb-4 text-justify ">
            <h1 className="text-4xl font-bold mb-3">Our vision.</h1>
            <p>
              "Automation is paramount" the idea of bridging the gap between two
              generations that are supreme in their own way and to use this
              amalgam to invent solutions that are the epitome of productivity,
              quality and accuracy, is something that is the foundation of our
              idea and we like to carry our thoughts in resonance with our idea
              to implement the decisions we make.
            </p>
          </div>
          <img
            src={ourVision}
            className="md:w-[462px] md:h-[319px]"
            alt="our vision"
          />
        </div>
        <div className="mb-5 md:mb-0 md:flex md:flex-row sm:flex-col gap-10 items-center md:shadow-none shadow-lg rounded-xl py-5 px-5 md:p-0">
          <img
            src={ourValues}
            className="md:w-[462px] md:h-[319px]"
            alt="our values"
          />
          <div className="mb-4 mt-4 text-justify ">
            <h1 className="text-4xl font-bold mb-3">Our Values</h1>
            <p>
              We operate on a strong foundation based on certain principles and
              values echoed throughout our company. Sphrava operates on trust,
              integrity, ethics, embraces diversity, makes decisions based on
              inclusion, and possesses a deep passion for spear-heading future
              automation.
            </p>
          </div>
        </div>
        <div className="mb-5 md:mb-0 md:flex md:flex-row sm:flex-col gap-10 items-center md:shadow-none shadow-lg rounded-xl py-5 px-5 md:p-0">
          <div className="mb-4 text-justify ">
            <h1 className="text-4xl font-bold mb-3">Our Capabilities.</h1>
            <p>
              We operate on a strong foundation based on certain principles and
              values echoed throughout our company. Sphrava operates on trust,
              integrity, ethics, embraces diversity, makes decisions based on
              inclusion, and possesses a deep passion for spear-heading future
              automation.
            </p>
          </div>
          <img
            src={ourCapabilities}
            className="md:w-[462px] md:h-[319px]"
            alt="our capabilities"
          />
        </div>
      </div>
    </section>
  );
};

export default Goal;
