import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/icons/icons8-search-50.png"; // Import the search icon

const jobListings = [
  {
    title: "Software Engineer",
    location: "New York, NY",
    description:
      "Develop and maintain web applications using React and Node.js.",
  },
  {
    title: "Product Manager",
    location: "San Francisco, CA",
    description: "Oversee product development from ideation to launch.",
  },
  {
    title: "UX Designer",
    location: "Remote",
    description:
      "Design user-friendly interfaces for our web and mobile applications.",
  },
];

const JobCard = ({ title, location, description, onApply }) => {
  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "16px",
    margin: "16px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    flex: "1 1 300px",
    maxWidth: "300px",
    textAlign: "left",
    transition: "transform 0.2s",
  };

  const cardHoverStyle = {
    transform: "scale(1.02)",
  };

  const titleStyle = {
    color: "#4169E1",
  };

  const buttonStyle = {
    background: "#333",
    color: "#fff",
    border: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    marginTop: "12px",
    cursor: "pointer",
  };

  const buttonHoverStyle = {
    background: "#000",
  };

  return (
    <div
      style={{ ...cardStyle }}
      onMouseEnter={(e) =>
        (e.currentTarget.style.transform = cardHoverStyle.transform)
      }
      onMouseLeave={(e) => (e.currentTarget.style.transform = "none")}
    >
      <h2 style={titleStyle}>{title}</h2>
      <h4>{location}</h4>
      <p>{description}</p>
      <button
        style={buttonStyle}
        onClick={onApply}
        onMouseEnter={(e) =>
          (e.currentTarget.style.background = buttonHoverStyle.background)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.background = buttonStyle.background)
        }
      >
        Apply Now
      </button>
    </div>
  );
};

const Careers = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const pageStyle = {
    padding: "32px",
    textAlign: "center",
    marginTop: "5rem",
    fontFamily: "Arial, sans-serif",
  };

  const titleStyle = {
    color: "#4169E1",
    fontSize: "64px",
    marginBottom: "8px",
    fontStyle: "italic",
  };

  const subtitleStyle = {
    color: "#333",
    fontSize: "24px",
    marginBottom: "24px",
  };

  const listingsStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  };

  const searchWrapperStyle = {
    position: "relative",
    margin: "16px auto",
    maxWidth: "400px",
    width: "100%",
  };

  const searchStyle = {
    padding: "12px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ddd",
    paddingRight: "40px", // Add space for the icon
  };

  const searchIconStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    width: "24px",
    height: "24px",
  };

  const buttonStyle = {
    background: "#0056b3",
    color: "#fff",
    border: "none",
    padding: "16px 32px",
    borderRadius: "4px",
    marginTop: "24px",
    cursor: "pointer",
    display: "block",
    margin: "0 auto",
    fontSize: "16px",
  };

  const mobileListingsStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const handleApply = (jobTitle) => {
    alert(`You applied for the ${jobTitle} position.`);
  };

  const handleCheckAllPositions = () => {
    alert("Displaying all open positions.");
  };

  return (
    <div style={pageStyle}>
      <h1 style={titleStyle}>Careers</h1>
      <h2 style={subtitleStyle}>At Sprhava</h2>
      <div style={searchWrapperStyle}>
        <input style={searchStyle} type="text" placeholder="Search Keywords" />
        <img src={searchIcon} alt="Search Icon" style={searchIconStyle} />
      </div>
      <div style={isMobile ? mobileListingsStyle : listingsStyle}>
        {jobListings.map((job, index) => (
          <JobCard
            key={index}
            title={job.title}
            location={job.location}
            description={job.description}
            onApply={() => handleApply(job.title)}
          />
        ))}
      </div>
      <button style={buttonStyle} onClick={handleCheckAllPositions}>
        Check All Open Positions
      </button>
    </div>
  );
};

export default Careers;
