import React from "react";
import ananth from "../../assets/ananth.png";
import pranay from "../../assets/pranay.png";
import jyotsana from "../../assets/jyotsana.png";
import varun from "../../assets/varun.png";
import Anup from "../../assets/Anup.png";
import tushar from "../../assets/tushar.png";
import praveen from "../../assets/praveen.png";
const Team = () => {
  return (
    <section title="team" className=" bg-[#f1f6ff] py-24 mb-20">
      <div className="mx-auto w-[90%]">
        <h1 className="mb-10 font-black text-4xl">
          Meet <span className="text-[#F29834]">The Team</span>
        </h1>
        <div className="flex justify-center gap-10 flex-wrap">
          <div className="relative md:w-[25rem] w-[28rem] border-2 border-black py-5">
            <img src={ananth} alt="ananth" className="h-[30vh] mx-auto" />
            <div className="bg-[#424242] text-white absolute left-0 md:top-[17rem] top-[16rem] px-5 py-2">
              <h1 className="font-semibold">Anantyraj Narasappa</h1>
              <p className="text-sm">CEO</p>
            </div>

            <div className="mt-10 px-5 text-justify">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <a
                href="https://www.linkedin.com/in/narasappa-ananthraj-28b8954b/"
                target="_blank"
                rel="noreferrer"
                className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="relative md:w-[25rem] w-[28rem] border-2 border-black py-5">
            <img src={varun} alt="varun" className="h-[30vh] mx-auto" />
            <div className="bg-[#424242] text-white  absolute left-0 md:top-[17rem] top-[16rem] px-5 py-2">
              <h1 className="font-semibold">Varun Parameswara</h1>
              <p className="text-sm">Co-Founder / Marketing Head (Germany)</p>
            </div>

            <div className="mt-10 px-5 text-justify">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <a
                href="https://www.linkedin.com/in/varun-parameswara-464991188/"
                target="_blank"
                rel="noreferrer"
                className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="relative md:w-[25rem] w-[28rem] border-2 border-black py-5 px-3">
            <img src={Anup} alt="Anup" className="h-[30vh] mx-auto" />
            <div className="bg-[#424242] text-white absolute left-0 md:top-[17rem] top-[16rem] px-5 py-2">
              <h1 className="font-semibold">Anup Mohon Siddalingeswara</h1>
              <p className="text-sm">Project Management (Germany)</p>
            </div>

            <div className="mt-10 px-5 text-justify">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <a
                href="https://www.linkedin.com/in/ams14697/"
                target="_blank"
                rel="noreferrer"
                className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="relative md:w-[25rem] w-[28rem] border-2 border-black py-5 px-3">
            <img src={tushar} alt="tushar" className="h-[30vh] mx-auto" />
            <div className="bg-[#424242] text-white  absolute left-0 md:top-[17rem] top-[16rem] px-5 py-2">
              <h1 className="font-semibold">Tushar</h1>
              <p className="text-sm">AI Lead (India)</p>
            </div>
            <div className="mt-10 px-5 text-justify">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <a
                href="https://www.linkedin.com/in/t-riisc/"
                target="_blank"
                rel="noreferrer"
                className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="relative md:w-[25rem] w-[28rem] border-2 border-black py-5 px-3">
            <img src={pranay} alt="pranay jain" className="h-[30vh] mx-auto" />
            <div className="bg-[#424242] text-white  absolute left-0 md:top-[17rem] top-[16rem] px-5 py-2">
              <h1 className="font-semibold">Pranay Jain</h1>
              <p className="text-sm">Product Owner </p>
            </div>
            <div className="mt-10 px-5 text-justifysssssssss">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <a
                href="https://www.linkedin.com/in/pranay-jain26/"
                target="_blank"
                rel="noreferrer"
                className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="relative md:w-[25rem] w-[28rem] border-2 border-black py-5 px-3">
            <img src={jyotsana} alt="jyotsana" className="h-[30vh] mx-auto" />
            <div className="bg-[#424242] text-white  absolute left-0 md:top-[17rem] top-[16rem] px-5 py-2">
              <h1 className="font-semibold">Jythosna Anand</h1>
              <p className="text-sm">Co-Founder & Operation Head</p>
            </div>
            <div className="mt-10 px-5 text-justify">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <a
                href="https://www.linkedin.com/in/jyothsnaanand/"
                target="_blank"
                rel="noreferrer"
                className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="relative md:w-[25rem] w-[28rem] border-2 border-black py-5 px-3">
            <img src={praveen} alt="praveen" className="h-[30vh] mx-auto" />
            <div className="bg-[#424242] text-white  absolute left-0 md:top-[17rem] top-[16rem]  px-5 py-2">
              <h1 className="font-semibold">Praveen Kumar</h1>
              <p className="text-sm">CTO Of Sprhava</p>
            </div>
            <div className="mt-10 px-5 text-justify">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <br />
              <a
                href="https://www.linkedin.com/in/praveen-zummit/"
                target="_blank"
                rel="noreferrer"
                className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
