import React from "react";
import HeroImage from "../../assets/banner.png";

import Partners from "./Partners";
import MeetAdvisor from "./MeetAdvisor";
import ProjectHighlight from "./ProjectHighlight";
import Aim from "./Aim";

const Home = () => {
  return (
    <section title="Landing Page" className="relative mt-24">
      <div className="bg-gradient-to-r from-[#FFBA7A] to-[#FFE975] h-[83px] w-full flex justify-center items-center">
        <h1 className="font-bold text-[1.5rem] md:text-[2.25rem] text-center px-4">
          <span className="text-[#5397FF]">spharva</span> at ‘
          <span className="text-[#A8004F]">We Make Future</span>’ Italy!
        </h1>
      </div>
      <div
        className="h-[70vh] md:h-[95vh] w-full overflow-hidden bg-center bg-cover relative"
        style={{ backgroundImage: `url(${HeroImage})` }}
      >
        <div className="absolute top-[8rem] md:top-[10rem] left-5 md:left-28 w-full md:w-[25rem] px-4">
          <h3 className="text-[2rem] md:text-[64px] font-bold absolute left-9 md:left-14">
            sprhava
          </h3>
          <div className="absolute top-[2rem] md:top-14">
            <h1 className="text-[#9F672A] text-[3rem] md:text-[128px] leading-none">
              Spectacles
            </h1>
            <p className="text-[#5F5F5F] text-center font-bold text-[1rem] md:text-[1.5rem] mt-4 md:mt-0">
              Unobtrusive Access To This Visual World
            </p>
          </div>
        </div>
      </div>
      <section className="md:px-20 py-10">
        <Aim />
        <ProjectHighlight />
        <Partners />
        <MeetAdvisor />
      </section>
    </section>
  );
};

export default Home;
