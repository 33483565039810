import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./Style.css";

import callIcon from "../../assets/icons/callicon.png";
import locationIcon from "../../assets/icons/locationicon.png";
import emailIcon from "../../assets/icons/emailicon.png";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log("this is data ",data)
    try {
      const response = await axios.post(
         "https://sprhava.com/sprhava_web/api/contactus_save.php",
        //"http://localhost/sprhava/apis/contactus_save",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Data submitted successfully:", data);
      } else {
        console.error("Error submitting data:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const contactLeftRef = useRef(null);
  const contactFormRef = useRef(null);

  useEffect(() => {
    if (contactLeftRef.current && contactFormRef.current) {
      const contactFormHeight = contactFormRef.current.clientHeight;
      contactLeftRef.current.style.height = `${contactFormHeight}px`;
    }
  }, []);

  return (
    <div className="contact-wrapper">
      <div className="contact-left" ref={contactLeftRef}>
        <h1>Contact Us!</h1>
        <p>
          Contact us about anything related to our company or services. We'll do
          our best to get back to you as soon as possible.
        </p>
        <div className="contact-details">
          <div className="contact-item">
            <img src={locationIcon} alt="Location Icon" />
            <p>Raintaler Street 81539, Munich, Germany</p>
          </div>
          <div className="contact-item">
            <img src={callIcon} alt="Phone Icon" />
            <p>+49 89 37012778</p>
          </div>
          <div className="contact-item">
            <img src={emailIcon} alt="Email Icon" />
            <p>info@sprhava.com</p>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.205025312168!2d-122.08624628468128!3d37.42199997982508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb760af110cfb%3A0x44b96db7fb2b01!2s3575%20Buena%20Vista%20Ave%2C%20Santa%20Clara%2C%20CA%2095011%2C%20USA!5e0!3m2!1sen!2s!4v1591427694248!5m2!1sen!2s"
          width="100%"
          height="300"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="contact-form"
        ref={contactFormRef}
      >
        <label>Enter Full Name *</label>
        <input {...register("fullName", { required: true })} />
        {errors.fullName && <p className="error">Full Name is required</p>}

        <label>Phone Number *</label>
        <div className="phone-input">
          <select {...register("countryCode", { required: true })}>
            <option value="+1"> Canada (+1)</option>
            <option value="+1"> USA (+1)</option>
            <option value="+49"> Germany (+49)</option>
            <option value="+39"> Italy (+39)</option>
            <option value="+81"> Japan (+81)</option>
            <option value="+61"> Australia (+61)</option>
          </select>
          <input
            {...register("phoneNumber", {
              required: true,
              pattern: /^[0-9]+$/,
            })}
          />
        </div>
        {errors.phoneNumber && (
          <p className="error">Valid Phone Number is required</p>
        )}

        <label>Enter Email *</label>
        <input type="email" {...register("email", { required: true })} />
        {errors.email && <p className="error">Email is required</p>}

        <label>Company</label>
        <input {...register("company")} />

        <label>Subject *</label>
        <input {...register("subject", { required: true })} />
        {errors.subject && <p className="error">Subject is required</p>}

        <label>Question *</label>
        <textarea {...register("question", { required: true })}></textarea>
        {errors.question && <p className="error">Question is required</p>}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactUs;
