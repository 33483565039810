import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="w-full bg-transparent absolute z-50">
      <div className="h-16 bg-white flex justify-between items-center md:px-10 py-2 relative">
        <Link to={"/"}>
          <img src={Logo} alt="brand logo" className="w-[175px] h-[45px]" />
        </Link>
        <div className="md:hidden px-4">
          <span
            className="material-symbols-outlined md:hidden cursor-pointer text-4xl"
            onClick={toggleNavbar}
          >
            menu
          </span>
        </div>
        <div className="hidden md:flex gap-10 px-4 text-xl">
          <Link
            to={"/"}
            className="font-semibold text-black hover:text-orange-500"
            onClick={toggleNavbar}
          >
            Solutions
          </Link>
          <Link
            to={"/about-us"}
            className="font-semibold text-black hover:text-orange-500"
            onClick={toggleNavbar}
          >
            About
          </Link>
          <Link
            to={"/careers"}
            className="font-semibold text-black hover:text-orange-500"
            onClick={toggleNavbar}
          >
            Career
          </Link>
        </div>
        <div className="hidden md:block px-4">
          <Link
            to={"/contact-us"}
            className="font-bold text-xl text-white text-center px-8 py-2 rounded-lg bg-gradient-to-r from-[#3999E0] to-[#828282] hover:no-underline hidden sm:inline-block transition-all duration-500 hover:bg-gradient-to-r hover:from-[#ffffff] hover:to-[#ffffff] hover:border-2 hover:text-black"
            rel="noopener noreferrer"
          >
            Contact
          </Link>
        </div>
      </div>
      {isOpen && (
        <div className="text-2xl bg-white absolute top-full left-0 w-full flex flex-col items-center space-y-10 py-10 h-[40rem] sm:hidden">
          <Link
            to={"/"}
            className="font-semibold hover:text-orange-500"
            onClick={toggleNavbar}
          >
            Solutions
          </Link>
          <Link
            to={"/about-us"}
            className="font-semibold hover:text-orange-500"
            onClick={toggleNavbar}
          >
            About
          </Link>
          <Link
            to={"/careers"}
            className="font-semibold hover:text-orange-500"
            onClick={toggleNavbar}
          >
            Career
          </Link>
          <Link
            to={"/contact-us"}
            className="font-bold text-xl text-white text-center px-8 py-2 rounded-lg bg-gradient-to-r from-[#3999E0] to-[#828282] hover:no-underline md:hidden transition-all duration-500 hover:bg-gradient-to-r hover:from-[#ffffff] hover:to-[#ffffff] hover:border-2 hover:text-black"
            rel="noopener noreferrer"
          >
            Contact
          </Link>
        </div>
      )}
    </header>
  );
};

export default Navbar;
