import React from "react";
import vijay from "../../assets/vijay.png";
import Vector from "../../assets/Vector.svg";
import manoj from "../../assets/manoj.png";

const MeetAdvisor = () => {
  return (
    <section
      title="meet advisor"
      className="bg-gradient-to-t from-[#DCEFFF] to-[#F5FAFF] mt-36 py-10"
    >
      <div className="container mx-auto px-4 lg:px-0">
        <h1 className="text-3xl lg:text-5xl font-bold mb-3 lg:mb-5">
          Meet Our Advisors
        </h1>
        <img src={Vector} alt="vector" className="w-full lg:w-[35rem] mb-12" />
        <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-12">
          <div className="w-full lg:w-[413px] h-[400px] py-5 bg-white shadow-xl rounded-xl">
            <img
              src={manoj}
              className="w-[222px] h-[225px] mx-auto"
              alt="manoj"
            />
            <div className="bg-[#616161] text-white font-bold text-center py-2 mt-3">
              Mr. Manoj Joshi
            </div>
            <div className="mx-auto py-5 lg:py-10 text-center">
              <p>Vice President at eSystems Bank of America</p>
            </div>
          </div>
          <div className="w-full lg:w-[413px] h-[400px] py-5 bg-white shadow-lg rounded-lg">
            <img
              src={vijay}
              className="w-[222px] h-[225px] mx-auto"
              alt="vijay"
            />
            <div className="bg-[#616161] text-white font-bold text-center py-2 mt-3">
              Dr. Vijayraghavan Ramaiah
            </div>
            <div className="mx-auto py-5 lg:py-10 text-center">
              <p>DNB Nuclear Medicine FASNC, MNAMS (India)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetAdvisor;
