import React from "react";
import Vector from "../../assets/Vector.svg";

const Aim = () => {
  return (
    <section title="Aim" className="px-4 md:px-10 py-8 md:py-12">
      <div className="bg-[#FAFAFA] shadow-lg rounded-md border-l-[12px] border-blue-500 w-full md:w-[80%] mx-auto p-6 md:p-10 mb-8 md:mb-10">
        <h1 className="text-[24px] md:text-[40px] font-bold">Our Vision</h1>
        <img src={Vector} alt="vector" className="my-2" />
        <div className="w-full md:w-[60%] mx-auto mt-6 md:mt-8 space-y-4 md:space-y-8">
          <p>
            <span className="text-orange-500">Automation with Purpose:</span>{" "}
            Every task can be optimized. Our obsession with efficiency drives us
            to find opportunities for automation, leading to higher
            productivity, lower costs, and reduced time.
          </p>
          <p>
            <span className="text-orange-500">Data-Driven Excellence:</span>{" "}
            Digitalization is our compass. Real-time data collection and
            analysis empower us to make informed decisions, resulting in greater
            efficiency, increased revenue, and better risk mitigation.
          </p>
          <p>
            <span className="text-orange-500">Precision AI Models:</span> Our
            team of data scientists and machine learning experts transforms raw
            data into actionable insights. Sprhava’s AI models are customizable,
            allowing deep personalization through the training process.
          </p>
          <p>
            <span className="text-orange-500">Real-Time Predictions:</span> Gone
            are the days of guesswork. With Sprhava, you get real-time,
            data-driven conversations powered by programmatic AI models.
          </p>
          <p>
            <span className="text-orange-500">Healthcare and Beyond:</span>{" "}
            We’re not stopping at healthcare. Our vision extends to the
            automotive industry and beyond. We’re here to shape the future, one
            algorithm at a time. Our Current Endeavor
          </p>
        </div>
      </div>
      <div className="bg-[#FAFAFA] shadow-lg rounded-md border-l-[12px] border-blue-500 w-full md:w-[80%] mx-auto p-6 md:p-10">
        <h1 className="text-[24px] md:text-[40px] font-bold">Our Mission</h1>
        <img src={Vector} alt="vector" className="my-2" />
        <div className="w-full md:w-[60%] mx-auto mt-6 md:mt-8 space-y-4 md:space-y-8">
          <p>
            We strive to provide the best automation solutions for the
            healthcare sector. By harnessing the power of Artificial
            Intelligence, Machine Learning, and Deep Learning, we empower
            healthcare professionals, streamline processes, and improve patient
            outcomes.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Aim;