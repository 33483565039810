import React from "react";
import Spec from "../../assets/specImg.png";

const ProjectHighlight = () => {
  return (
    <section
      title="project_highlight"
      className="bg-gradient-to-r from-[#E0F5FE] to-[#96E0FF] py-10 md:py-20"
    >
      <div className="flex flex-col md:flex-row justify-center items-center px-4 md:px-20">
        <div className="md:mr-8">
          <div className="space-y-6 md:space-y-10 mb-10 md:mb-20">
            <p className="text-[16px] md:text-[20px] max-w-[20rem]">
              AI Powered Spectacles for Visually Impaired individuals from
              ground up based on Qualcomm Snapdragon silicon.
            </p>
            <h2 className="text-[36px] md:text-[48px] text-[#2B48E0]">
              Let us delve into the fascinating world of AI-powered spectacles.
            </h2>
          </div>
          <a
            href="/"
            className="inline-block px-6 py-2 rounded-full border border-[#FA8734] font-bold text-[14px] md:text-[sm] text-gray-600"
          >
            Learn More
          </a>
        </div>
        <img
          src={Spec}
          alt="spectale"
          className="w-full md:w-[465px] h-auto md:h-[315px] max-w-[90vw] md:max-w-none"
        />
      </div>
    </section>
  );
};

export default ProjectHighlight;
